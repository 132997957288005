<template>
  <div>
    <b-card
      class="col-lg-8 col-sm-12"
    >
      <b-row>
        <b-col class="col-8">
          <h1>Activités</h1>
          <p>Liste des activités</p>
        </b-col>
        <b-col class="col-4 text-right" align-h="end">
          <b-row align-h="end">
            <b-link @click="triggerAddActivity()">
              <i class="fas fa-plus"></i> Ajouter
            </b-link>
          </b-row>
        </b-col>
      </b-row>

      <b-table
        :items="activities"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :busy="isBusy"
        ref="activityTable"
      >

        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>

        <template #cell(icon)="data">
          <span v-html="data.item.icon"></span>
        </template>

        <template #cell(actions)="data">
          <b-link @click="triggerEditActivity(data.item)"><b-icon icon="pencil"></b-icon></b-link>
          <b-link @click="triggerDeleteActivity(data.item)" class="text-warning pl-1"><b-icon icon="trash"></b-icon></b-link>
        </template>

      </b-table>
    </b-card>
    <b-modal
      id="modalActivityForm"
      :title="formTitle"
      ref="activity-form-modal"
      v-model="modalShow"
      @hidden="modalHiddenAction"
      hide-footer
    >
      <b-form
        @submit.prevent="triggerSubmitForm()"
      >
        <b-row>
          <b-col class="col-6">
            <label for="inputName">Nom</label>
            <b-form-input id="inputName" class="form-control" v-model="activity.name"></b-form-input>
          </b-col>
          <b-col>
            <label for="inputFullname">Nom complet</label>
            <b-form-input id="inputFullname" class="form-control" v-model="activity.fullName"></b-form-input>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col>
            <label for="inputIcon">Icone</label>
            <b-form-input id="inputIcon" class="form-control" v-model="activity.icon"></b-form-input>
          </b-col>
        </b-row>
        <b-row align-h="end" class="mr-2">
          <b-button @click="$bvModal.hide('modalActivityForm')" class="btn btn-secondary mt-2" variant="secondary">Annuler</b-button>
          <b-button type="Submit" class="btn btn-primary mt-2" variant="primary">{{ activityFormButtonSubmitName }}</b-button>
        </b-row>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import activities from "../store/module/activities";

export default {
  name: "AdminActivity",
  data() {
    return {
      fields: [
        {key: 'name', label:'Nom', Sortable:true},
        {key: 'fullName', label:'Nom Complet', Sortable:true},
        {key: 'icon', label:'Icone', Sortable:true},
        {key:'actions', label:'', Sortable: false},
      ],
      sortBy: 'name',
      sortDesc: false,
      editEnable: false,
      isBusy: true,
      activity:{
        name: "",
        icon: "",
        fullName: ""
      },
      modalShow:false,
      editActivityId:0
    };
  },
  mounted() {
    this.loadActivities().then((response)=>{
      this.isBusy=false;
      if(response !== 200){
        this.notif('warning', 'Erreur lors de la récupération des données')
      }
    });
  },
  computed: {
    ...mapGetters({
      activities:'activities/activities',
    }),
    formTitle () {
      return this.editEnable ? 'Modification d\'une activité' : 'Création d\'une activité' ;
    },
    activityFormButtonSubmitName(){
      return this.editEnable ? 'Mettre à jour' : 'Ajouter'
    },
  },
  methods: {
    ...mapActions({
      loadActivities:'activities/loadActivities',
      addActivity:'activities/addActivity',
      deleteActivity:'activities/deleteActivity',
      updateActivity:'activities/updateActivity',
    }),
    notif(status, message) {
      this.$notify({verticalAlign: 'top', horizontalAlign: 'right', message: message, type: status})
    },
    modalHiddenAction () {
      this.clearForm();
    },
    clearForm () {
      this.activity.name = '';
      this.activity.fullName = '';
      this.activity.icon = '';

      this.editEnable = false;
    },
    triggerAddActivity(){
      this.$refs['activity-form-modal'].show();
    },
    triggerEditActivity(activity){
      this.activity.name = activity.name;
      this.activity.fullName = activity.fullName;
      this.activity.icon = activity.icon;

      this.editActivityId = activity.id;

      this.$refs['activity-form-modal'].show();

      this.editEnable = true;

    },
    triggerSubmitForm() {
      if (this.editEnable) {
        this.updateActivity({activity: this.activity, activityId: this.editActivityId})
        .then((response) => {
          if(response === 200){
            this.notif('success', 'Activité mise à jour');
            this.$refs['activity-form-modal'].hide();
          }else{
            this.notif('warning', 'Erreur lors de la mise à jour de l\'activities');
          }
        })
        .catch((error) => {
          this.notif('warning', 'Erreur lors de la mise à jour de l\'activities');
        })
      } else {
        this.addActivity(this.activity)
          .then((response) => {
            if(response.status === 201){
              this.notif('success', 'L\'activité a été ajoutée');
              this.$refs['activity-form-modal'].hide();
            }else{
              this.notif('warning', 'Erreur lors de l\'ajout de l\'activité');
            }
          })
          .catch(reject => {
            this.notif('warning', 'Erreur lors de l\'ajout de l\'activité');
          });
      }
    },
    triggerDeleteActivity(activity){
      this.deleteActivity(activity.id)
        .then(response => {
          if(response === 204){
            this.notif('success','Activité supprimé');
          }else{
            this.notif('warning', 'Erreur lors de la suppression1');
          }
        })
      .catch(()=>{
        this.notif('warning', 'Erreur lors de la suppression2');
      })
    },
  },

}
</script>

<style scoped>

</style>
