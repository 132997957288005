<template>
  <div>
    <b-card
      class="col-lg-8 col-sm-12"
    >
      <b-row>
        <b-col class="col-8">
          <h1>Sens de parcours</h1>
          <p>Liste des sens de parcours</p>
        </b-col>
        <b-col class="col-4 text-right" align-h="end">
          <b-row align-h="end">
            <b-link @click="triggerAddDirection()">
              <i class="fas fa-plus"></i> Ajouter
            </b-link>
          </b-row>
        </b-col>
      </b-row>

      <b-table
        :items="directions"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :busy="isBusy"
        ref="directionTable"
      >

        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>

        <template #cell(icon)="data">
          <span v-html="data.item.icon"></span>
        </template>

        <template #cell(actions)="data">
          <b-link @click="triggerEditDirection(data.item)"><b-icon icon="pencil"></b-icon></b-link>
          <b-link @click="triggerDeleteDirection(data.item)" class="text-warning pl-1"><b-icon icon="trash"></b-icon></b-link>
        </template>

      </b-table>
    </b-card>
    <b-modal
      id="modalDirectionForm"
      :title="formTitle"
      ref="direction-form-modal"
      v-model="modalShow"
      @hidden="modalHiddenAction"
      hide-footer
    >
      <b-form
        @submit.prevent="triggerSubmitForm()"
      >
        <b-row>
          <b-col class="col-6">
            <label for="inputName">Nom</label>
            <b-form-input id="inputName" class="form-control" v-model="direction.name"></b-form-input>
          </b-col>
          <b-col>
            <label for="inputFullname">Nom complet</label>
            <b-form-input id="inputFullname" class="form-control" v-model="direction.fullName"></b-form-input>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col>
            <label for="inputIcon">Icon</label>
            <b-form-input id="inputIcon" class="form-control" v-model="direction.icon"></b-form-input>
          </b-col>
        </b-row>
        <b-row align-h="end" class="mr-2">
          <b-button @click="$bvModal.hide('modalDirectionForm')" class="btn btn-secondary mt-2" variant="secondary">Annuler</b-button>
          <b-button type="Submit" class="btn btn-primary mt-2" variant="primary">{{ directionFormButtonSubmitName }}</b-button>
        </b-row>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';

export default {
  name: "AdminDirection",
  data() {
    return {
      fields: [
        {key: 'name', label:'Nom', Sortable:true},
        {key: 'fullName', label:'Nom Complet', Sortable:true},
        {key: 'icon', label:'Icone', Sortable:true},
        {key:'actions', label:'', Sortable: false},
      ],
      sortBy: 'name',
      sortDesc: false,
      editEnable: false,
      isBusy: true,
      direction:{
        name: "",
        icon: "",
        fullname: ""
      },
      modalShow:false,
      editDirectionId:0
    };
  },
  mounted() {
    this.loadDirections().then((response)=>{
      this.isBusy=false;
      if(response !== 200){
        this.notif('warning', 'Erreur lors de la récupération des données')
      }
    });
  },
  computed: {
    ...mapGetters({
      directions:'directions/directions',
    }),
    formTitle () {
      return this.editEnable ? 'Modification d\'un sens' : 'Création d\'un sens' ;
    },
    directionFormButtonSubmitName(){
      return this.editEnable ? 'Mettre à jour' : 'Ajouter'
    },
  },
  methods: {
    ...mapActions({
      loadDirections:'directions/loadDirections',
      addDirection:'directions/addDirection',
      deleteDirection:'directions/deleteDirection',
      updateDirection:'directions/updateDirection',
    }),
    notif(status, message) {
      this.$notify({verticalAlign: 'top', horizontalAlign: 'right', message: message, type: status})
    },
    modalHiddenAction () {
      this.clearForm();
    },
    clearForm () {
      this.direction.name = '';
      this.direction.fullName = '';
      this.direction.icon = '';
    },
    triggerAddDirection(){
      this.$refs['direction-form-modal'].show();
    },
    triggerEditDirection(direction){
      this.direction.name = direction.name;
      this.direction.fullName = direction.fullName;
      this.direction.icon = direction.icon;

      this.editDirectionId = direction.id;

      this.$refs['direction-form-modal'].show();

      this.editEnable = true;
    },
    triggerSubmitForm() {
      if (this.editEnable) {
        this.updateDirection({direction: this.direction, directionId: this.editDirectionId})
          .then((response) => {
            if(response === 200){
              this.notif('success', 'Sens mise à jour');
              this.$refs['direction-form-modal'].hide();
            }else{
              this.notif('warning', 'Erreur lors de la mise à jour du sens1');
            }
          })
          .catch(() => {
            this.notif('warning', 'Erreur lors de la mise à jour du sens2');
          })
      } else {
        this.addDirection(this.direction)
          .then((response) => {
            if(response === 201)
            {
              this.notif('success', 'Le sens a été ajoutée');
              this.clearForm();
              this.$refs['direction-form-modal'].hide();
            }else{
              this.notif('warning', 'Erreur lors de l\'ajout du sens');
            }
          })
          .catch(reject => {
            this.notif('warning', 'Erreur lors de l\'ajout du sens');
          });
      }
    },
    triggerDeleteDirection(direction){
      this.deleteDirection(direction.id)
        .then(response => {
          if(response === 204){
            this.notif('success','Sens supprimé');
          }else{
            this.notif('warning', 'Erreur lors de la suppression');
          }
        })
        .catch(()=>{
          this.notif('warning', 'Erreur lors de la suppression');
        })
    },
  },

}
</script>

<style scoped>

</style>
