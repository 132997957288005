<template>
  <div>
    <b-card
      class="col-lg-8 col-sm-12"
    >
      <b-row>
        <b-col class="col-8">
          <h1>Statut</h1>
          <p>Liste des statut</p>
        </b-col>
        <b-col class="col-4 text-right" align-h="end">
          <b-row align-h="end">
            <b-link @click="triggerAddStatus()">
              <i class="fas fa-plus"></i> Ajouter
            </b-link>
          </b-row>
        </b-col>
      </b-row>

      <b-table
        :items="statuses"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :busy="isBusy"
        ref="statusTable"
      >

        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>

        <template #cell(icon)="data">
          <span v-html="data.item.icon"></span>
        </template>

        <template #cell(actions)="data">
          <b-link @click="triggerEditStatus(data.item)"><b-icon icon="pencil"></b-icon></b-link>
          <b-link @click="triggerDeleteStatus(data.item)" class="text-warning pl-1"><b-icon icon="trash"></b-icon></b-link>
        </template>

      </b-table>
    </b-card>
    <b-modal
      id="modalStatusForm"
      :title="formTitle"
      ref="status-form-modal"
      v-model="modalShow"
      @hidden="modalHiddenAction"
      hide-footer
    >
      <b-form
        @submit.prevent="triggerSubmitForm()"
      >
        <b-row>
          <b-col class="col-6">
            <label for="inputName">Nom</label>
            <b-form-input id="inputName" class="form-control" v-model="status.name"></b-form-input>
          </b-col>
          <b-col class="col-6">
            <label for="inputStatusOrder">Ordre</label>
            <b-form-input id="inputStatusOrder" class="form-control" v-model.number="status.statusOrder" type="number"></b-form-input>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col class="col-6">
            <label for="inputIcon">Icon</label>
            <b-form-input id="inputIcon" class="form-control" v-model="status.icon"></b-form-input>
          </b-col>

        </b-row>
        <b-row align-h="end" class="mr-2">
          <b-button @click="$bvModal.hide('modalStatusForm')" class="btn btn-secondary mt-2" variant="secondary">Annuler</b-button>
          <b-button type="Submit" class="btn btn-primary mt-2" variant="primary">{{ statusFormButtonSubmitName }}</b-button>
        </b-row>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';

export default {
  name: "AdminStatus",
  data() {
    return {
      fields: [
        {key: 'name', label:'Nom', Sortable:true},
        {key: 'statusOrder', label: 'Ordre', Sortable: false},
        {key: 'icon', label:'Icone', Sortable:true},
        {key:'actions', label:'', Sortable: false},
      ],
      sortBy: 'name',
      sortDesc: false,
      editEnable: false,
      isBusy: true,
      status:{
        name: "",
        icon: "",
        statusOrder:0
      },
      modalShow:false,
      editStatusId:0
    };
  },
  mounted() {
    this.loadStatuses().then((response)=>{
      this.isBusy=false;
      if(response !== 200){
        this.notif('warning', 'Erreur lors de la récupération des données')
      }
    });
  },
  computed: {
    ...mapGetters({
      statuses:'statuses/statuses',
    }),
    formTitle () {
      return this.editEnable ? 'Modification d\'un statut' : 'Création d\'un statut' ;
    },
    statusFormButtonSubmitName(){
      return this.editEnable ? 'Mettre à jour' : 'Ajouter'
    },
  },
  methods: {
    ...mapActions({
      loadStatuses:'statuses/loadStatuses',
      addStatus:'statuses/addStatus',
      deleteStatus:'statuses/deleteStatus',
      updateStatus:'statuses/updateStatus',
    }),
    notif(status, message) {
      this.$notify({verticalAlign: 'top', horizontalAlign: 'right', message: message, type: status})
    },
    modalHiddenAction () {
      this.clearForm();
    },
    clearForm () {
      this.status.name = '';
      this.status.statusOrder = 0;
      this.status.icon = '';
    },
    triggerAddStatus(){
      this.$refs['status-form-modal'].show();
    },
    triggerEditStatus(status){
      this.status.name = status.name;
      this.status.statusOrder = parseInt(status.statusOrder,10);
      this.status.icon = status.icon;

      this.editStatusId = status.id;

      this.$refs['status-form-modal'].show();

      this.editEnable = true;
    },
    triggerSubmitForm() {
      if (this.editEnable) {
        this.updateStatus({status: this.status, statusId: this.editStatusId})
          .then((response) => {
            if(response === 200){
              this.notif('success', 'Statut mise à jour');
              this.$refs['status-form-modal'].hide();
            }else{
              this.notif('warning', 'Erreur lors de la mise à jour du statut');
            }
          })
          .catch((error) => {
            this.notif('warning', 'Erreur lors de la mise à jour du statut');
          })
      } else {
        this.addStatus(this.status)
          .then((response) => {
            if(response === 201)
            {
              this.notif('success', 'Le statut a été ajoutée');
              this.clearForm();
              this.$refs['status-form-modal'].hide();
            }else{
              this.notif('warning', 'Erreur lors de l\'ajout du statut1');
            }
          })
          .catch(reject => {
            this.notif('warning', 'Erreur lors de l\'ajout du statut2');
          });
      }
    },
    triggerDeleteStatus(status){
      this.deleteStatus(status.id)
        .then(response => {
          if(response === 204){
            this.notif('success','Statut supprimé');
          }else{
            this.notif('warning', 'Erreur lors de la suppression');
          }
        })
        .catch(()=>{
          this.notif('warning', 'Erreur lors de la suppression');
        })
    },
  },

}
</script>

<style scoped>

</style>
