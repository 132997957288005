<template>
  <div>
    <b-container fluid class="mt-7">
      <admin-activity></admin-activity>
      <div class="pt-3"></div>
      <admin-direction></admin-direction>
      <div class="pt-3"></div>
      <admin-status></admin-status>
    </b-container>

  </div>
</template>
<script>

import AdminActivity from "./SettingsActivity";
import AdminDirection from "./SettingsDirection";
import AdminStatus from "./SettingsStatus";

export default {
  name: 'Maps',
  components:{
    AdminActivity,
    AdminDirection,
    AdminStatus,
  },
  data() {
    return {
    };
  },
  mounted() {
  },
  computed: {
  },
  methods: {
  }
};
</script>
<style>

</style>
